import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import HeroHome from "../partials/HeroHome";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";
import BlogListPartial from "../partials/BlogListPartial";

const BlogList = (props) => {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <MetaTags>
        <title>Quasi Blog</title>
        <meta name='description' content='Quasi Blog Nexus Page' />
        <meta property='og:title' content='Anything Expert | Home' />
      </MetaTags>

      {/*  Site header */}

      {/*  Page content */}
      <main className='flex-grow'>
        {/*  Page sections */}
        <Header />

        <BlogListPartial />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
};

export default BlogList;
