import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { getBlogList } from "../merlinv1/beta_api";

function BlogListPartial() {
  const [blogTitles, setBlogTitles] = useState([]);
  const [blogImages, setBlogImages] = useState([]);
  const [blogDates, setBlogDates] = useState([]);
  const [blogcid, setBlogcid] = useState([]);

  const fetchData = async () => {
    try {
      let blogListObject = await getBlogList(1);
      console.log(blogListObject.data[0]);
      setBlogTitles(blogListObject.data[0]["title"]);
      setBlogImages(blogListObject.data[0]["images"]);
      setBlogDates(blogListObject.data[0]["date"]);
      setBlogcid(blogListObject.data[0]["cid"]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className='pt-8'></div>
      <div className='flex flex-wrap m-auto align-center justify-items-center w-full pl-10 pr-10'>
        {/* Hero content */}
        <div
          className='lg:text-5xl md:text-4xl sm:text-3xl font-semibold leading-tighter tracking-tighter mb-4 text-left mx-1 w-full'
          data-aos='fade-up'>
          <p className='flex flex-col justify-left'>Latest Blogs</p>
        </div>
        <div className='w-full grid lg:grid-cols-2 md:grid-cols-1 gap-4'>
          {blogTitles.map((blog, index) => (
            <div
              className='w-full bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox rounded-2xl'
              data-aos='fade-up'
              onClick={() => {
                window.location.href = "/blog=" + blogcid[index];
              }}>
              <div className='flex flex-row p-4'>
                <div className='w-1/2'>
                  <div className='p-4'>
                    <img
                      className='w-full rounded-2xl'
                      src={blogImages[index]}
                      alt='Blog Image'
                    />
                  </div>
                </div>
                <div className='w-full pt-4 pb-4'>
                  <div className='h-full flex flex-col justify-between'>
                    <div>
                      <div className='lg:text-3xl md:text-3xl sm:text-3xl font-medium leading-tighter tracking-tighter mb-4 text-left mx-1 w-full text-gray-800'>
                        {blog}
                      </div>
                      {/* <div className='lg:text-2xl md:text-2xl sm:text-xl font-medium leading-tighter tracking-tighter mb-4 text-left mx-1 w-full text-gray-800'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </div> */}
                    </div>
                    <div>
                      <div className='lg:text-xl md:text-xl sm:text-xl font-medium leading-tighter tracking-tighter text-left mx-1 w-full text-gray-800 bottom-0'>
                        {blogDates[index]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='py-16' />
    </div>
  );
}

export default BlogListPartial;
