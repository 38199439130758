import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./css/style.scss";
import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";
// Landing pages:
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import BlogList from "./pages/BlogList";
import Blog from "./pages/Blog";

// Required StyleSheets
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import firebase from "firebase";
import "firebase/auth";

const App = (props) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, []);

  return (
    <Switch>
      <Route exact path='/home' render={() => <Redirect to={"/"} />} />

      <Route exact path='/'>
        <Home />
      </Route>
      <Route exact path='/list'>
        <BlogList />
      </Route>
      <Route exact path='/blog=*'>
        <Blog />
      </Route>
      <Route exact path='/*'>
        <PageNotFound />
      </Route>
      {/* Protected routes are enables only when user signs in */}
    </Switch>
  );
};

export default App;
