// this file will contain the Axios setup link between API and Front-end
import merlinApp from "../AxiosSetup.js";
import firebase from "firebase/app";
import "firebase/auth";

// POST request functions

export const getBlog = async (creationId) => {
  return merlinApp.post("/getBlog/" + creationId, {});
};

export const getBlogList = async (page) => {
  return merlinApp.post("/getBlogList", {
    data: {
      page: page,
    },
  });
};

export const makeBlog = async (user_input) => {
  return merlinApp.post("/makeBlog", {
    data: {
      user_input: user_input,
    },
  });
};

export const viewBlog = async (creationId) => {
  return merlinApp.post("/viewBlog", {
    data: {
      creationId: creationId,
    },
  });
};

// Method is going to log a specific event in Cloud Firestore
export const logFirestoreEvent = (eventName, eventPaylod) => {
  if (window.location.host !== "www.letspondr.com") {
    if (eventName === "404 Error") {
      console.log({ eventName, eventPaylod });
    }
  } else {
    return merlinApp.post("/log", {
      data: {
        key: "RH5cFnBB0t",
        event_name: eventName,
        event_payload: eventPaylod,
      },
    });
  }
};
