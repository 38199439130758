import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../store/actions/authActions";
import Transition from "../utils/Transition.js";

const Header = (props) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);

  const mobileNav = useRef(null);

  // close the mobile menu on click outsidemy
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const history = useHistory();

  // Declares a variable to store the className of the header links. Also checks to see which tab is selected
  const baseHeaderClassname =
    "text-black hover:text-blue-pondr mx-3 lg:mx-5 py-2 flex items-center transition duration-150 ease-in-out outline-none text-md";
  const currentSelectedPathname = window.location.pathname;

  return (
    <header className={`bg-gray-100`}>
      <div className='text-md xl:px-12 lg:px-0 sm:px-2 lg:ml-3 font-medium mx-auto w-full'>
        <div className='text-md flex items-center justify-between h-16 md:h-20  outline-none'>
          {/* Site branding */}
          {/* Logo */}
          <Link to='/' className='block' aria-label='Cruip'>
            <img
              className='mx-auto outline-none'
              src={
                "https://storage.googleapis.com/quasi-a39a6.appspot.com/Quasi_Blog.webp"
              }
              width='231'
              height='140'
              alt='Hero'
            />
          </Link>

          {/* Desktop navigation */}
          <nav className='text-md hidden md:flex md:flex-grow'>
            {/* Desktop menu links */}
            <ul className='flex text-md flex-grow justify-end flex-wrap items-center'>
              <li>
                <Link
                  to='/list'
                  className={
                    "btn-sm font-medium text-blue-primaryText bg-blue-primaryBox hover:bg-blue-hoverBox hover:text-blue-hoverText rounded-2xl"
                  }>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 24 23'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M23.25 16.8584L23.2354 2.16602C23.2354 1.02344 22.4736 0.24707 21.3164 0.24707H6.60938C5.52539 0.24707 4.73438 1.06738 4.73438 2.04883C4.73438 3.01562 5.56934 3.80664 6.58008 3.80664H11.8975L17.5664 3.60156L14.9443 5.93066L1.42383 19.4658C1.04297 19.8467 0.823242 20.3301 0.823242 20.7988C0.823242 21.751 1.70215 22.6592 2.68359 22.6592C3.16699 22.6592 3.62109 22.4541 4.0166 22.0586L17.5518 8.53809L19.8955 5.91602L19.6611 11.3652V16.8877C19.6611 17.9131 20.4521 18.748 21.4336 18.748C22.415 18.748 23.25 17.9277 23.25 16.8584Z'
                      fill='#003150'
                    />
                  </svg>

                  <span className='pl-2'>Latest Blogs</span>
                </Link>
              </li>
            </ul>

            {/* Desktop sign in links */}
          </nav>

          {/* Mobile menu */}
          <div className='flex md:hidden'>
            {/* Hamburger button */}
            <button
              className={`hamburger ${
                mobileNavOpen && "active"
              } focus:outline-none`}
              aria-controls='mobile-nav'
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className='sr-only'>Menu</span>
              <svg
                className='w-6 h-6 fill-current text-gray-900'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'>
                <rect y='4' width='24' height='2' />
                <rect y='11' width='24' height='2' />
                <rect y='18' width='24' height='2' />
              </svg>
            </button>

            {/* Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag='nav'
                id='mobile-nav'
                className='absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white transition-all duration-300 ease-in-out'
                enter='transition ease-out duration-200 transform'
                enterStart='opacity-0 -translate-y-2'
                enterEnd='opacity-100 translate-y-0'
                leave='transition ease-out duration-200'
                leaveStart='opacity-100'
                leaveEnd='opacity-0'>
                <ul className='px-5 py-2'>
                  <li>
                    <Link
                      to='/investors'
                      className='flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center'>
                      Investors
                    </Link>
                  </li>
                </ul>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (creds) => {
      dispatch(signOut(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
