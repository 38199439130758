import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeBlog } from "../merlinv1/beta_api";

function HeroHome() {
  let phrases = [
    "AIing ",
    "Doing Research ",
    "Painting Pictures ",
    "Editing ",
    "Finishing Little Details ",
  ];

  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [blogID, setBlogID] = useState("");
  const [blogLink, setBlogLink] = useState("https://quasi.blog/list");
  const [showButton, setShowButton] = useState(false);
  const [buttonLoadingText, setButtonLoadingText] = useState("");
  const [copy, setCopy] = useState(false);

  const timeout = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const timeoutfunc = async () => {
    let index = 0;
    let loadingWord = phrases[index];
    for (let i = 0; i < 100; i++) {
      setButtonLoadingText(loadingWord + i + "%");
      await timeout(700);
      if (i % 20 === 0 && i > 1) {
        loadingWord = phrases[index + 1];
        index += 1;
      }
    }
    setButtonLoadingText("");
  };

  const handleClick = async () => {
    setLoading(true);
    const timeoutfuncPromise = new Promise((resolve, reject) => {
      timeoutfunc()
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
    const res = await makeBlog(userInput);
    console.log(res);
    console.log(res.data[0]["cid"]);
    setBlogID(res.data[0]["cid"]);
    setBlogLink("https://quasi.blog/blog=" + res.data[0]["cid"]);
    setLoading(false);
    setShowButton(true);
  };

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  return (
    <div>
      <div className='flex flex-wrap m-auto align-center justify-items-center text-center w-full pl-10 pr-10'>
        {/* Hero content */}
        <div className='w-full flex flex-col justify-center'>
          <div className='flex justify-center w-full' data-aos='fade-up'>
            <img
              src='https://storage.googleapis.com/quasi-a39a6.appspot.com/Blog_Hero.webp'
              width={600}
              height={600}
            />
          </div>
          <div
            className='lg:text-6xl md:text-5xl sm:text-3xl font-semibold leading-tighter tracking-tighter mb-4 text-center mx-1 w-full'
            data-aos='fade-up'>
            <p className='flex flex-col justify-center'>Write A Blog Below.</p>
          </div>
          <div
            className='lg:text-4xl md:text-3xl sm:text-xl font-semibold leading-tighter tracking-tighter mb-4 text-center mx-1 w-full pb-16'
            data-aos='fade-up'>
            <div className='flex justify-center'>
              <p className='lg:w-1/2 md:w-full'>
                Just type in your company’s URL and we will take care of the
                rest!
              </p>
            </div>
          </div>
          <div className='flex justify-center' data-aos='fade-up'>
            <div className='lg:w-1/2 md:w-full sm:w-full'>
              <input
                className='md:text-lg sm:text-sm px-4 p-3 text-black w-full bg-gray-secondaryBox rounded-xl border-opacity-0 focus:border-opacity-0'
                name='website'
                placeholder='Webiste URL'
                type='text'
                maxLength={150}
                onChange={(e) => setUserInput(e.target.value)}
              />
            </div>
          </div>
          <div className='flex flex-row justify-center pt-3'>
            <div
              className={
                "btn-sm font-medium text-green-primaryText bg-green-primaryBox hover:bg-green-hoverBox hover:text-green-hoverText rounded-2xl w-64"
              }
              data-aos='fade-up'
              onClick={async () => {
                handleClick();
              }}>
              {!loading ? (
                <div className='flex flex-row jutify-center'>
                  <div className='flex flex-col justify-center'>
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 29 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M2.4248 0.0458984C1.26758 0.0458984 0.754883 0.924805 0.623047 1.73047C0.491211 2.41895 0.461914 3.19531 0.461914 4.04492C0.461914 10.7979 3.33301 13.9912 9.99805 13.9912H19.2852L22.7129 13.7861L18.7871 17.3457L15.5205 20.6416C15.1836 20.9785 14.9639 21.418 14.9639 21.9453C14.9639 22.9414 15.7109 23.6885 16.7656 23.6885C17.2197 23.6885 17.6738 23.498 18.084 23.1025L27.6641 13.5225C28.0449 13.1709 28.25 12.6582 28.25 12.1602C28.25 11.6621 28.0449 11.1641 27.6641 10.7979L18.0547 1.20312C17.6738 0.822266 17.2197 0.631836 16.7656 0.631836C15.7109 0.631836 14.9639 1.37891 14.9639 2.375C14.9639 2.91699 15.1836 3.35645 15.5205 3.67871L18.7871 6.97461L22.7275 10.5488L19.2852 10.3291H10.2471C5.76465 10.3291 4.15332 8.49805 4.15332 3.97168C4.15332 3.07812 4.21191 2.43359 4.21191 1.84766C4.21191 0.763672 3.49414 0.0458984 2.4248 0.0458984Z'
                        fill='#003A22'
                      />
                    </svg>
                  </div>
                  <span className='pl-2'>Generate Blog</span>
                </div>
              ) : (
                <div className='flex flex-row '>
                  <span className='pl-2'>{buttonLoadingText}</span>
                </div>
              )}
            </div>
            {showButton ? (
              <a
                data-aos='fade-up'
                href={blogLink}
                target='_blank'
                className={
                  "btn-sm font-medium text-blue-primaryText bg-blue-quasi hover:bg-blue-hoverBox hover:text-blue-hoverText rounded-2xl mx-4"
                }>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 24 23'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M23.25 16.8584L23.2354 2.16602C23.2354 1.02344 22.4736 0.24707 21.3164 0.24707H6.60938C5.52539 0.24707 4.73438 1.06738 4.73438 2.04883C4.73438 3.01562 5.56934 3.80664 6.58008 3.80664H11.8975L17.5664 3.60156L14.9443 5.93066L1.42383 19.4658C1.04297 19.8467 0.823242 20.3301 0.823242 20.7988C0.823242 21.751 1.70215 22.6592 2.68359 22.6592C3.16699 22.6592 3.62109 22.4541 4.0166 22.0586L17.5518 8.53809L19.8955 5.91602L19.6611 11.3652V16.8877C19.6611 17.9131 20.4521 18.748 21.4336 18.748C22.415 18.748 23.25 17.9277 23.25 16.8584Z'
                    fill='#003150'
                  />
                </svg>
                <span className='pl-2'>View Blog</span>
              </a>
            ) : null}

            {showButton ? (
              <div
                data-aos='fade-up'
                onClick={async () => {
                  copyTextToClipboard(blogLink);
                  setCopy(true);
                  await timeout(1000);
                  setCopy(false);
                }}
                className={
                  "btn-sm bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox rounded-2xl"
                }>
                {!copy ? (
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 34 33'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M5.94531 25.3154H8.40625V27.498C8.40625 30.7207 10.1348 32.4199 13.3721 32.4199H28.2988C31.5361 32.4199 33.2646 30.7061 33.2646 27.498V12.4834C33.2646 9.27539 31.5361 7.56152 28.2988 7.56152H25.8379V5.37891C25.8379 2.1709 24.1094 0.47168 20.8721 0.47168H5.94531C2.69336 0.47168 0.979492 2.1709 0.979492 5.37891V20.3936C0.979492 23.6162 2.69336 25.3154 5.94531 25.3154ZM6.22363 22.0635C4.93457 22.0635 4.24609 21.4043 4.24609 20.0566V5.73047C4.24609 4.38281 4.93457 3.72363 6.22363 3.72363H20.5938C21.8682 3.72363 22.5713 4.38281 22.5713 5.73047V7.56152H13.3721C10.1348 7.56152 8.40625 9.27539 8.40625 12.4834V22.0635H6.22363ZM13.6504 29.1533C12.3613 29.1533 11.6729 28.5088 11.6729 27.1611V12.8203C11.6729 11.4727 12.3613 10.8281 13.6504 10.8281H28.0205C29.2949 10.8281 29.998 11.4727 29.998 12.8203V27.1611C29.998 28.5088 29.2949 29.1533 28.0205 29.1533H13.6504ZM14.0898 19.998C14.0898 20.8477 14.7051 21.4629 15.5693 21.4629H19.3633V25.2715C19.3633 26.1211 19.9785 26.7363 20.8281 26.7363C21.707 26.7363 22.3223 26.1211 22.3223 25.2715V21.4629H26.1309C26.9951 21.4629 27.5957 20.8477 27.5957 19.998C27.5957 19.1191 26.9951 18.5039 26.1309 18.5039H22.3223V14.71C22.3223 13.8311 21.707 13.2305 20.8281 13.2305C19.9785 13.2305 19.3633 13.8457 19.3633 14.71V18.5039H15.5693C14.7051 18.5039 14.0898 19.1191 14.0898 19.998Z'
                      fill='#333333'
                    />
                  </svg>
                ) : (
                  <svg
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M6.98828 17.7188C7.56445 17.7188 8.01367 17.4941 8.32617 17.0254L17.4668 2.88477C17.7012 2.5332 17.7891 2.2207 17.7891 1.91797C17.7891 1.14648 17.2227 0.589844 16.4316 0.589844C15.8848 0.589844 15.5527 0.785156 15.2207 1.3125L6.94922 14.418L2.71094 9.04688C2.38867 8.64648 2.05664 8.4707 1.57812 8.4707C0.777344 8.4707 0.201172 9.03711 0.201172 9.81836C0.201172 10.1602 0.318359 10.4824 0.601562 10.8242L5.66016 17.0547C6.03125 17.5137 6.44141 17.7188 6.98828 17.7188Z'
                      fill='black'
                    />
                  </svg>
                )}
                <span className='pl-2'>Copy Blog Link</span>
              </div>
            ) : null}
          </div>
          <div className='py-16' />
        </div>
      </div>
    </div>
  );
}

export default HeroHome;
